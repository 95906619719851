import {
  recipeFormReducer,
  RecipeFormAction,
  RecipeFormState,
  recipeFormToInput,
  canSaveRecipeFormTooltips,
  recipeServingFormToInput,
} from "../Form/utils";
import { recipeToFormState } from "../utils";
import { EditRecipeFullInput, EditRecipePartialInput, RecipeFullFragment } from "../../../types";

export interface EditRecipeState {
  id: string;
  isRevision: boolean;
  pendingRevisionAction: RecipeFormAction | null;
  form: RecipeFormState;
}

export interface Image {
  url: string;
  position: number;
}

interface MakeRevision {
  type: "MAKE_REVISION";
}

interface CancelRevision {
  type: "CANCEL_REVISION";
}

type EditRecipeAction = RecipeFormAction | MakeRevision | CancelRevision;

export const editRecipeReducer = (state: EditRecipeState, action: EditRecipeAction): EditRecipeState => {
  switch (action.type) {
    case "CANCEL_REVISION":
      return {
        ...state,
        pendingRevisionAction: null,
      };
    case "MAKE_REVISION":
      return {
        ...state,
        isRevision: true,
        pendingRevisionAction: null,
        form: state.pendingRevisionAction ? recipeFormReducer(state.form, state.pendingRevisionAction) : state.form,
      };
    case "CHANGE_STEPS":
    case "CHANGE_COOK_TIME":
    case "CHANGE_PREP_TIME":
    case "CHANGE_NAME":
    case "CHANGE_IMAGES":
    case "CHANGE_NOTE":
    case "CHANGE_IS_SHARED":
    case "CHANGE_MEAL_TYPES":
    case "CHANGE_SCORE":
      return {
        ...state,
        form: recipeFormReducer(state.form, action),
      };
    case "CHANGE_RECIPE_SCALE":
      return {
        ...state,
        isRevision: true,
        form: recipeFormReducer(state.form, action),
      };
    default:
      if (state.isRevision) {
        return {
          ...state,
          form: recipeFormReducer(state.form, action),
        };
      } else {
        return {
          ...state,
          pendingRevisionAction: action,
        };
      }
  }
};

export const recipeToEditState = (recipe: RecipeFullFragment): EditRecipeState => {
  return {
    id: recipe.id,
    isRevision: false,
    pendingRevisionAction: null,
    form: recipeToFormState(recipe),
  };
};

type EditRecipeInput =
  | {
      type: "full";
      input: EditRecipeFullInput;
    }
  | {
      type: "partial";
      input: EditRecipePartialInput;
    };

export const editRecipeStateToInput = (state: EditRecipeState): EditRecipeInput | null => {
  const canSaveTooltips = canSaveRecipeFormTooltips(state.form);
  const input = recipeFormToInput(state.form);
  if (canSaveTooltips.length > 0 || !input) {
    return null;
  }

  if (state.isRevision) {
    const createServings = [state.form.serving].flatMap(s => {
      return s.id ? [] : [recipeServingFormToInput(s)];
    });
    const editServings = [state.form.serving].flatMap(s => {
      return s.id ? [{ id: s.id, serving: recipeServingFormToInput(s) }] : [];
    });
    return {
      type: "full",
      input: {
        id: state.id,
        recipe: input,
        createServings,
        editServings,
      },
    };
  } else {
    const { name, steps, note, prepTimeInMinutes, cookTimeInMinutes, images, isShared, mealTypes, scoreValue, foodCategory } = input;
    return {
      type: "partial",
      input: {
        id: state.id,
        name,
        steps,
        note,
        prepTimeInMinutes,
        cookTimeInMinutes,
        images,
        isShared,
        mealTypes: mealTypes || [],
        scoreValue,
        foodCategory,
      },
    };
  }
};
