import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, Grid, TextField, Typography, useTheme } from "@mui/material";
import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useRecipesPage } from "apps/web/src/views/Recipes/components/useRecipesPage";
import React, { Dispatch, useState } from "react";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import { RecipeFormAction, RecipeFormState, scaleTheRecipeWithYield } from "../Form/utils";
import { RecipeUnsavedChangesDialog } from "./RecipeUnsavedChangesDialog";

interface RecipeScaleDialogProps {
  state: RecipeFormState;
  initialFormState: RecipeFormState;
  dispatch: Dispatch<RecipeFormAction>;
  openScaleChangeDialog: boolean;
  setOpenScaleChangeDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openRecipeUnsavedChangesDialog: boolean;
  setOpenRecipeUnsavedChangesDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  openScaleRecipe: (value: boolean) => void;
  onScaleAndCreateNewRecipe?: (state: RecipeFormState) => void;
}

export const RecipeScaleDialog: React.FC<RecipeScaleDialogProps> = ({
  state,
  initialFormState,
  dispatch,
  openScaleChangeDialog,
  setOpenScaleChangeDialog,
  openRecipeUnsavedChangesDialog,
  setOpenRecipeUnsavedChangesDialog,
  onSuccess,
  openScaleRecipe,
  onScaleAndCreateNewRecipe,
}) => {
  const { setEditRecipe } = useRecipesPage();
  const [recipeYieldInput, setRecipeYieldInput] = useState<{
    value: string;
    touched: boolean;
    error: boolean;
  }>({
    value: "",
    touched: false,
    error: false,
  });
  const { palette, spacing } = useTheme();
  const { setMessage } = useSnackbar();

  const onSave = () => {
    if (!recipeYieldInput.error) {
      dispatch({
        type: "CHANGE_RECIPE_SCALE",
        payload: scaleTheRecipeWithYield(inputToNumber(recipeYieldInput.value) || 0, state),
      });
      handleClose();
      onSuccess(true);
    }
  };

  const createNewRecipe = () => {
    if (!recipeYieldInput.error) {
      handleClose();
      onScaleAndCreateNewRecipe?.(scaleTheRecipeWithYield(inputToNumber(recipeYieldInput.value) || 0, state));
      setEditRecipe(null);
    }
  };

  const getScaledAmount = (value: number) => {
    const newValue = inputToNumber(recipeYieldInput.value);
    const perYieldValue = inputToNumber(state.serving.perRecipeYieldInput) || 1;
    if (newValue == null) {
      return value;
    }

    return value * (newValue / perYieldValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = inputToNumber(e.target.value);

    setRecipeYieldInput(prevState => ({
      ...prevState,
      value: e.target.value,
      error: value == null || value <= 0,
    }));
  };

  const handleInputBlur = () =>
    setRecipeYieldInput(prevState => ({
      ...prevState,
      touched: true,
    }));

  const handleClose = () => {
    setOpenScaleChangeDialog(false);
    openScaleRecipe(false);
    setRecipeYieldInput({
      value: "",
      touched: false,
      error: false,
    });
  };

  const discardUnsavedChanges = () => {
    setOpenRecipeUnsavedChangesDialog(false);
    dispatch({
      type: "CHANGE_RECIPE_SCALE",
      payload: initialFormState,
    });
    setOpenScaleChangeDialog(true);
  };

  const onConfirmUnsavedChanges = () => {
    setOpenRecipeUnsavedChangesDialog(false);
    setOpenScaleChangeDialog(true);
    setMessage("success", "Recipe details have been saved");
  };

  return (
    <>
      <RecipeUnsavedChangesDialog
        open={openRecipeUnsavedChangesDialog}
        title="Unsaved Changes"
        message={`The menu has unsaved changes. Please make sure to save the menu in order to publish it.`}
        onCancel={() => {
          setOpenScaleChangeDialog(true);
          setOpenRecipeUnsavedChangesDialog(false);
        }}
        onDiscard={() => discardUnsavedChanges()}
        onConfirm={() => onConfirmUnsavedChanges()}
      />
      <Dialog
        onClose={handleClose}
        open={openScaleChangeDialog}
        maxWidth="sm"
        fullWidth>
        <DialogTitle title="Recipe Scale" onClose={handleClose} />
        <DialogContent sx={{ pt: 0 }}>
          <Box>
            <Typography variant="body1Semibold">New Recipe Yield</Typography>
            <Typography variant="body2">
              Enter the new yield (total number of servings) below. Only the ingredients will be scaled. Other details such as cook time,
              prep time, and instructions will not be scaled.
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField
                disabled
                label="Original Yield"
                fullWidth
                value={state.serving.perRecipeYieldInput} />
            </Grid>
            <NavigateNextIcon fontSize="large" sx={{ marginTop: 4.5, marginLeft: 1 }} />
            <Grid item xs={3}>
              <TextField
                label="New Yield"
                fullWidth
                placeholder="#"
                value={recipeYieldInput.value}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                error={recipeYieldInput.touched && recipeYieldInput.error}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box>
            <Typography variant="body1Semibold">Ingredients</Typography>
            <Typography variant="body2">Below is a preview of the scaled ingredient amounts based on the new yield.</Typography>
          </Box>
          <Box>
            {state.ingredients.map(ingredient => (
              <Box
                key={ingredient.serving.foodOrRecipe.name}
                sx={{
                  borderRadius: spacing(0.5),
                  padding: spacing(0.5),
                  paddingLeft: spacing(2),
                  mb: 1,
                  bgcolor: palette.greyscaleLight[100],
                }}
              >
                <Typography variant="body2Semibold">{ingredient.serving.foodOrRecipe.name}</Typography>
                <Typography variant="subtitle1">
                  {getScaledAmount(ingredient.amount)} {ingredient.serving.units}
                </Typography>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
          {!!onScaleAndCreateNewRecipe && (
            <Button variant="outlined" onClick={() => createNewRecipe()}>
              Save as New Recipe
            </Button>
          )}
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
            <Button onClick={() => onSave()}>Save</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
