import { Alert, AlertTitle } from "@mui/material";
import React from "react";

const RecipeScaleSuccessAlert = ({ open, onClose }: { open: boolean; onClose: () => void }) =>
  open ? (
    <Alert
      severity="success"
      sx={theme => ({
        backgroundColor: theme.palette.success.lighter,
      })}
      onClose={onClose}
    >
      <AlertTitle>Success</AlertTitle>
      Recipe has been scaled. Please note that you still have to edit the prep time, cook time, and instructions if needed.
    </Alert>
  ) : null;

export default RecipeScaleSuccessAlert;
