import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { Dispatch } from "react";
import { FullServingAmountFragment, RecipeWithServingsFragment } from "../../../types";
import { ServingAmountsEditChipListWithSearchBar } from "../../ServingAmounts/Edit/ChipListWithSearchBar";
import { NewChip } from "../../universal/NewChip";
import { RecipeFormAction, RecipeFormState } from "./utils";

interface IngredientsProps {
  state: RecipeFormState;
  dispatch: Dispatch<RecipeFormAction>;
  setNutrientsOpen: (value: boolean) => void;
  disabled?: boolean;
  onAiGenerateClicked: null | (() => void);
  onAiTranscribeClicked?: () => void;
}

export const Ingredients = ({
  state,
  dispatch,
  setNutrientsOpen,
  disabled,
  onAiGenerateClicked,
  onAiTranscribeClicked,
}: IngredientsProps) => {
  const handleChangeIngredients = (servingAmounts: readonly FullServingAmountFragment[], recipe?: RecipeWithServingsFragment) => {
    const containsSelf = servingAmounts.some(sa => sa.serving.foodOrRecipe.id === state.id);
    let containsDescendant = false;
    if (recipe && state.id) {
      containsDescendant = recipe.descendantRecipes.map(r => r.id).includes(state.id);
    }
    if (containsSelf || containsDescendant) {
      return;
    }
    dispatch({
      type: "CHANGE_INGREDIENTS",
      value: servingAmounts,
    });
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h3" color="textSecondary">
          Ingredients
        </Typography>
        <IconButton size="small" onClick={() => setNutrientsOpen(true)}>
          <BarChartIcon sx={{ color: "accents.yellow.400" }} />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <ServingAmountsEditChipListWithSearchBar
          disabled={disabled}
          enableBranded={true}
          selectedServingAmounts={state.ingredients}
          selected
          noCache
          includeRecipeIngredients
          onChange={handleChangeIngredients}
          sx={{ overflowY: "auto" }}
        />
        <Box
          display="flex"
          flexDirection="column"
          gap={1}>
          {onAiGenerateClicked && (
            <Button
              variant="outlined"
              fullWidth
              onClick={() => onAiGenerateClicked()}>
              <AutoAwesomeIcon sx={{ fontSize: 16, marginRight: 1 }} />
              AI Recipe Generator
            </Button>
          )}
          {onAiTranscribeClicked && (
            /*TODO - remove NewChip once feature has been released for time*/
            <Button
              variant="outlined"
              fullWidth
              onClick={() => onAiTranscribeClicked()}>
              <AutoStoriesIcon sx={{ fontSize: 16, marginRight: 1 }} />
              AI Image/URL Transcriber
              <NewChip feature="transcribeRecipe" />
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
