import React from "react";
import { Route, Routes, useParams } from "react-router-dom-v5-compat";
import { useAthleteNavQuery } from "../../../../types";
import { RoleRoute } from "../../ProtectedRoutes";
import { NavLink } from "../../SecondaryNav/BreadCrumbs";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { getNavOrgTeamLink } from "../Team/TeamPaths";
import { teamManagementLink, teamsLink } from "../TeamManagement/TeamManagementPaths";
import { AthleteAnthropometryPage } from "./AthleteAnthropometryPage";
import { AthleteFoodLogsPage } from "./AthleteFoodLogsPage";
import { AthleteGoalCompliancePage } from "./AthleteGoalCompliancePage";
import { AthleteGoalsPage } from "./AthleteGoalsPage";
import { AthleteMealPlansPage } from "./AthleteMealPlansPage";
import { AthleteNotesPage } from "./AthleteNotesPage";
import {
  NAV_ATHLETE_ANTHROPOMETRY,
  NAV_ATHLETE_FOOD_LOGS,
  NAV_ATHLETE_GOALS,
  NAV_ATHLETE_MEAL_PLANS,
  NAV_ATHLETE_NOTES,
  NAV_ATHLETE_SUMMARY,
  getNavOrgAthleteLink,
} from "./AthletePaths";
import { AthleteSummaryPage } from "./AthleteSummaryPage";

const getSecondaryNavLayoutProps = (current: NavLink, teamLink: NavLink): SecondaryNavLayoutProps => ({
  name: "Athlete",
  current,
  breadCrumbs: [teamManagementLink, teamsLink, teamLink],
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Summary",
      to: NAV_ATHLETE_SUMMARY,
    },
    {
      name: "Meal Plans",
      to: NAV_ATHLETE_MEAL_PLANS,
    },
    {
      name: "Goals",
      to: NAV_ATHLETE_GOALS,
    },
    {
      name: "Food Logs",
      to: NAV_ATHLETE_FOOD_LOGS,
    },
    {
      name: "Anthropometry",
      to: NAV_ATHLETE_ANTHROPOMETRY,
    },
    {
      name: "Notes",
      to: NAV_ATHLETE_NOTES,
    },
  ],
});

export const AthleteRouter = () => {
  const { athleteId: maybeAthleteId } = useParams();
  const athleteId = maybeAthleteId || "";
  let athleteName = "";
  let teamId = "";
  let teamName = "";
  const { data, loading } = useAthleteNavQuery({ variables: { id: athleteId } });

  if (!loading && data) {
    const {
      athlete: {
        firstName,
        lastName,
        team: { id, name },
      },
    } = data;

    athleteName = `${lastName}, ${firstName}`;
    teamId = id;
    teamName = name;
  }

  const teamLink = getNavOrgTeamLink(teamId, teamName);
  const current = getNavOrgAthleteLink(athleteId, athleteName);
  const secondaryNavLayoutProps = getSecondaryNavLayoutProps(current, teamLink);

  return (
    <Routes>
      <Route element={athleteName && <SecondaryNavLayout {...secondaryNavLayoutProps} />}>
        <Route element={<RoleRoute roles={["dietitian"]} />}>
          <Route index element={<AthleteSummaryPage />} />
          <Route path={NAV_ATHLETE_SUMMARY} element={<AthleteSummaryPage />} />
          <Route path={NAV_ATHLETE_MEAL_PLANS} element={<AthleteMealPlansPage />} />
          <Route path={`${NAV_ATHLETE_MEAL_PLANS}/:mealPlanId?`} element={<AthleteMealPlansPage />} />
          <Route path={NAV_ATHLETE_FOOD_LOGS} element={<AthleteFoodLogsPage />} />
          <Route path={NAV_ATHLETE_ANTHROPOMETRY} element={<AthleteAnthropometryPage />} />
          <Route path={NAV_ATHLETE_NOTES} element={<AthleteNotesPage />} />
          <Route path={NAV_ATHLETE_GOALS} element={<AthleteGoalsPage />} />
        </Route>
      </Route>
      <Route path={`${NAV_ATHLETE_GOALS}/:goalConfigurationId`} element={<AthleteGoalCompliancePage />} />
    </Routes>
  );
};
