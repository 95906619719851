import { ApolloError } from "@apollo/client";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { xor } from "@notemeal/utils/generic";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { GptRecipeFragment, useGetPossibleGptRecipeFromImageMutation, useGetPossibleGptRecipeFromUrlMutation } from "../../../../types";
import { GPTLoadingContent } from "../GPTLoadingContent";
import { TranscribeWithGPTContent } from "./TranscribeWithGPTContent";
import { TranscribeError } from "./utils";
import { trackEvent } from "apps/web/src/reporting/reporting";

interface TranscribeWithGPTDialogProps {
  open: boolean;
  onClose: () => void;
  onTranscribeComplete: (gptRecipe: GptRecipeFragment, closeModal: () => void) => void;
  onError: (error: any) => void;
  transcriberError: TranscribeError | null;
  setTranscriberError: (error: TranscribeError | null) => void;
}

export const TranscribeWithGPTDialog = ({
  open,
  onClose,
  onTranscribeComplete,
  onError,
  transcriberError,
  setTranscriberError,
}: TranscribeWithGPTDialogProps) => {
  const [presignedGetImageUrl, setPresignedGetImageUrl] = useState("");
  const [recipeUrl, setRecipeUrl] = useState("");
  const [invalidUrl, setInvalidUrl] = useState(false);

  const [transcribeImage, { loading: transcribingImage }] = useGetPossibleGptRecipeFromImageMutation({
    onCompleted: data => {
      onTranscribeComplete(data.getPossibleGPTRecipeFromImage, onClose);
    },
  });
  const [transcribeUrl, { loading: transcribingUrl }] = useGetPossibleGptRecipeFromUrlMutation({
    onCompleted: data => {
      onTranscribeComplete(data.getPossibleGPTRecipeFromUrl, onClose);
    },
  });

  const loading = transcribingImage || transcribingUrl;

  const handleClose = () => {
    trackEvent("Nutrition | Food Management | Recipe | AI Recipe Transcribe | Cancel Transcription", {});
    if (loading) {
      return;
    }
    onClose();
  };

  const handleTranscribeError = (e: any, inputType: string) => {
    let message = `There was an issue transcribing your ${inputType}.`;
    if (e instanceof ApolloError) {
      if (e.message.includes("invalid image")) {
        // it's possible this is caused by a broken upload link - there should
        // be a log with the full error output from OpenAI
        message = message + " Please try again with a different image. If the issue persists, please reach out to customer support.";
      } else if (e.message.toLowerCase().includes("unreadable image")) {
        message = message + " The image is too blurry to transcribe.";
      } else if (e.message.toLowerCase().includes("no recipe found")) {
        message = message + ` Recipe content could not be found in the provided ${inputType}.`;
      } else if (e.message.toLowerCase().includes("cannot be translated")) {
        message = message + " The transcriber cannot translate non-English recipes at this time.";
      }
    }
    setPresignedGetImageUrl("");
    setRecipeUrl("");
    setTranscriberError(
      new TranscribeError({
        name: "GPT_ERROR",
        message: message,
        cause: e,
      })
    );
  };

  const handleTranscribe = async () => {
    if (presignedGetImageUrl) {
      await transcribeImage({ variables: { input: { presignedGetImageUrl: presignedGetImageUrl } } }).catch(e =>
        handleTranscribeError(e, "image")
      );
      trackEvent("Nutrition | Food Management | Recipe | AI Recipe Transcribe | Transcribe Image", {});
    } else if (recipeUrl) {
      await transcribeUrl({ variables: { input: { url: recipeUrl } } }).catch(e => handleTranscribeError(e, "URL"));
      trackEvent("Nutrition | Food Management | Recipe | AI Recipe Transcribe | Transcribe URL", { url: recipeUrl });
    }
  };

  const disableTranscribe = !xor(Boolean(presignedGetImageUrl), Boolean(recipeUrl)) || loading;

  const onChangeRecipeUrl = (url: string) => {
    setRecipeUrl(url);
    // check URL valid
    try {
      new URL(url);
    } catch (e) {
      setInvalidUrl(true);
    }
    const urlRegex = /^(((http|https):\/\/|)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;
    setInvalidUrl(!urlRegex.test(url));
  };
  // update copy to include error messages

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle title="AI Image/URL Transcriber" onClose={handleClose} />
      <DialogContent sx={{ pt: 1, pb: 12 }}>
        {loading ? (
          <GPTLoadingContent actionText="Transcribing" />
        ) : (
          <div>
            <TranscribeWithGPTContent
              presignedGetImageUrl={presignedGetImageUrl}
              setPresignedGetImageUrl={setPresignedGetImageUrl}
              recipeUrl={recipeUrl}
              onChangeRecipeUrl={onChangeRecipeUrl}
              invalidUrl={invalidUrl}
              transcriberError={transcriberError}
              setTranscriberError={setTranscriberError}
              onError={onError}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <span>
            <Button onClick={handleTranscribe} disabled={disableTranscribe}>
              Continue
            </Button>
          </span>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
