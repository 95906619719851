import { Grid, InputAdornment, SxProps, TextField, Typography } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import { useMyScoringSystemQuery } from "apps/web/src/types";
import React, { Dispatch, useState } from "react";
import NutrientAmountModal from "../../NutrientAmount/Modal";
import Details from "./Details";
import Images from "./Images/Images";
import { Ingredients } from "./Ingredients";
import { Instructions } from "./Instructions";
import RecipeFormServing from "./Serving";
import { RecipeFormAction, RecipeFormState } from "./utils";
import { UnmatchedIngredientsWarning } from "./UnmatchedIngredientsWarning";

interface RecipeFormProps {
  state: RecipeFormState;
  dispatch: Dispatch<RecipeFormAction>;
  sx?: SxProps;
  disabled?: boolean;
  forStaff?: boolean;
  onAiGenerateClicked: null | (() => void);
  onAiTranscribeClicked?: () => void;
  openScaleRecipe?: () => void;
}

const RecipeForm = ({
  state,
  dispatch,
  sx,
  disabled,
  forStaff,
  onAiGenerateClicked,
  onAiTranscribeClicked,
  openScaleRecipe,
}: RecipeFormProps) => {
  const [nutrientsOpen, setNutrientsOpen] = useState(false);

  const { data: scoringSystemData, loading: loadingScoringSystem } = useMyScoringSystemQuery();
  const scoringSystem = scoringSystemData?.myScoringSystem;

  return (
    <>
      {(state.unmatchedIngredients.unmatchedName.length > 0 || state.unmatchedIngredients.unmatchedServings.length > 0) && (
        <UnmatchedIngredientsWarning unmatchedIngredients={state.unmatchedIngredients} />
      )}
      <Grid
        container
        direction="row"
        spacing={4}
        sx={{
          display: "flex",
          alignItems: "stretch",
          textOverflow: "auto",
          ...sx,
        }}
      >
        {/** Left panel */}
        <Grid item xs={8}>
          <Grid
            container
            direction={"row"}
            spacing={3}>
            {/** Details and Servings */}
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  {loadingScoringSystem ? (
                    <Loading />
                  ) : (
                    <Details
                      forStaff={forStaff}
                      disabled={disabled}
                      state={state}
                      dispatch={dispatch}
                      scoringSystem={scoringSystem} />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <RecipeFormServing
                    disabled={disabled}
                    state={state}
                    dispatch={dispatch}
                    openScaleRecipe={openScaleRecipe}
                    forStaff={forStaff}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/** Ingredients and Directions/Notes */}
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Ingredients
                    disabled={disabled}
                    state={state}
                    dispatch={dispatch}
                    setNutrientsOpen={setNutrientsOpen}
                    onAiGenerateClicked={onAiGenerateClicked}
                    onAiTranscribeClicked={onAiTranscribeClicked}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Instructions
                    disabled={disabled}
                    steps={state.steps}
                    dispatch={dispatch} />

                  <Typography
                    variant="h3"
                    color="textSecondary"
                    sx={{ pt: 4 }}>
                    Notes
                  </Typography>
                  <TextField
                    disabled={disabled}
                    margin="dense"
                    fullWidth
                    value={state.note}
                    onChange={e =>
                      dispatch({
                        type: "CHANGE_NOTE",
                        value: e.target.value,
                      })
                    }
                    placeholder="Add a note to the recipe"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          disablePointerEvents
                          sx={{ opacity: disabled ? 0.33 : 1 }}>
                          <strong>*</strong>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Images
            disabled={disabled}
            dispatch={dispatch}
            state={state}
            sx={{}} />
        </Grid>
      </Grid>
      {nutrientsOpen && (
        <NutrientAmountModal
          open={nutrientsOpen}
          onClose={() => setNutrientsOpen(false)}
          servingAmounts={state.ingredients.map(sa => ({
            ...sa,
            amount: sa.amount / state.serving.perRecipeYield,
          }))}
        />
      )}
    </>
  );
};

export default RecipeForm;
