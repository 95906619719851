import React from "react";
import { RecipeFullFragment } from "../../../types";
import { RecipeFormState } from "../Form/utils";
import RecipeCreateDialog from "../RecipeCreateDialog";

interface RecipeCreateWithScaleDialogProps {
  open: boolean;
  onClose: () => void;
  onCreate: (recipe: RecipeFullFragment) => void;
  scaledRecipeState: RecipeFormState;
}

const RecipeCreateWithScaleDialog = ({ open, onClose, onCreate, scaledRecipeState }: RecipeCreateWithScaleDialogProps) => {
  const scaledRecipeForForm = { ...scaledRecipeState, name: scaledRecipeState.name + " [Scaled Copy]" };

  return (
    <RecipeCreateDialog
      open={open}
      onClose={onClose}
      onCreate={onCreate}
      initialRecipeState={scaledRecipeForForm}
      isSuccessAlert={true} />
  );
};

export default RecipeCreateWithScaleDialog;
