import { PrimaryNavLink, Team as TWTeam } from "@teamworksdev/react";
import { devEnv } from "../NavUtils";
import { NAV_UPDATE_CHECK } from "./AuthPaths";
import { orgDevLink, staffDevLink } from "./Dev/DevRouter";
import { dataLink } from "./Org/Data/DataRouter";
import { orgFoodManagementLink } from "./Org/FoodManagement/FoodManagementPaths";
import { kitchenLink } from "./Org/Kitchen/KitchenPaths";
import { mealPlansLink } from "./Org/MealPlans/MealPlansPaths";
import { messageLink } from "./Org/Message/MessagePaths";
import { orgSettingsLink } from "./Org/Settings/SettingsPaths";
import { teamManagementLink } from "./Org/TeamManagement/TeamManagementPaths";
import { FeatureFlagsDetails, OrgUserDetails } from "./OrgUtils";
import { staffFoodManagementLink } from "./Staff/FoodManagement/FoodManagementRouter";
import { formsAndScoresLink } from "./Staff/FormsAndScores/FormsAndScoresRouter";
import { mealPlanManagementLink } from "./Staff/MealPlanManagement/MealPlanManagementRouter";
import { orgManagementLink } from "./Staff/OrgManagement/OrgManagementRouter";
import { restaurantManagementLink } from "./Staff/RestaurantManagement/RestaurantManagementRouter";
import { staffSettingsLink } from "./Staff/Settings/SettingsPaths";

export const STAFF_ORG_ID = "staff";
export const STAFF_ORG_NAME = "TW Nutrition Staff";
export const DEFAULT_ORG_ID = "UnknownOrgId";
export const DEFAULT_USER_NAME = "UnknownUser";

export const staffOrg: TWTeam = {
  id: STAFF_ORG_ID,
  name: STAFF_ORG_NAME,
};

export interface OrgAndStaffRouterProps {
  orgs: TWTeam[];
  onOrgChange: (orgId: string) => void;
}

const updateCheckLink = {
  name: "Check for Updates",
  to: NAV_UPDATE_CHECK,
};

export const orgAdditionalSettingsLinks = [updateCheckLink, ...(devEnv ? [orgSettingsLink] : [])];
export const staffAdditionalSettingsLinks = [updateCheckLink, ...(devEnv ? [staffSettingsLink] : [])];

export const getOrgPrimaryNavLinks = (
  { hasRoleDietitian, hasRoleChef, hasRoleFoodManager, hasRoleKiosk }: OrgUserDetails,
  { hasPerformanceKitchen, hasMessaging }: FeatureFlagsDetails
): PrimaryNavLink[] => {
  return [
    ...(hasRoleDietitian ? [teamManagementLink] : []),
    ...(hasPerformanceKitchen && (hasRoleDietitian || hasRoleChef || hasRoleKiosk) ? [kitchenLink] : []),
    ...(hasRoleFoodManager ? [orgFoodManagementLink] : []),
    ...(hasRoleDietitian ? [mealPlansLink] : []),
    ...(hasMessaging && (hasRoleDietitian || hasRoleChef || hasRoleFoodManager) ? [messageLink] : []),
    ...(hasRoleDietitian ? [dataLink] : []),
    ...(devEnv ? [orgDevLink] : []),
  ];
};

export const getStaffPrimaryNavLinks = () => {
  return [
    orgManagementLink,
    staffFoodManagementLink,
    restaurantManagementLink,
    mealPlanManagementLink,
    formsAndScoresLink,
    ...(devEnv ? [staffDevLink] : []),
  ];
};

export const staffPrimaryNavTeam = {
  id: STAFF_ORG_ID,
  name: STAFF_ORG_NAME,
  //avatar: "https://images-teamworksapp.s3.amazonaws.com/164/organizationLogo/original/94453C44-C053-20D0-3B89AE8B1FB53613.png",
};
