import { useUser } from "apps/web/src/utils/tokens";
import React from "react";
import { useEditRecipeFullMutation, useEditRecipePartialMutation, useRecipeEditDialogQuery } from "../../../types";
import LoadingBackdrop from "../../universal/LoadingBackdrop";
import { RecipeFormState } from "../Form/utils";
import { RecipeEditDialogContent as Content } from "./RecipeEditDialogContent";

interface RecipeEditDialogProps {
  open: boolean;
  onClose: () => void;
  onEdit: () => void;
  recipeId: string;
  onDuplicate: () => void;
  forStaff?: boolean;
  readonly?: boolean;
  onScaleAndCreateNewRecipe: (state: RecipeFormState) => void;
}

export const RecipeEditDialog = ({
  open,
  onClose,
  onEdit,
  recipeId,
  onDuplicate,
  forStaff,
  readonly,
  onScaleAndCreateNewRecipe,
}: RecipeEditDialogProps) => {
  const user = useUser();
  const { data, loading } = useRecipeEditDialogQuery({
    variables: {
      id: recipeId,
    },
  });

  const isOrgGroupReadonly = Boolean(data?.recipe?.orgGroup) && Boolean(user) && user?.id !== data?.recipe.owner.id;

  const onComplete = () => {
    onClose();
    onEdit();
  };

  const [editRecipeFull, { loading: savingFull }] = useEditRecipeFullMutation({
    onCompleted: () => {
      onComplete();
    },
  });

  const [editRecipePartial, { loading: savingPartial }] = useEditRecipePartialMutation({
    onCompleted: () => {
      onComplete();
    },
  });

  return savingFull || savingPartial || loading || !data ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <Content
      open={open}
      onClose={onClose}
      onEditPartial={input => editRecipePartial({ variables: { input } })}
      onEditFull={input => editRecipeFull({ variables: { input } })}
      recipe={data.recipe}
      onDuplicate={onDuplicate}
      forStaff={forStaff}
      readonly={readonly || isOrgGroupReadonly}
      onScaleAndCreateNewRecipe={onScaleAndCreateNewRecipe}
    />
  );
};
